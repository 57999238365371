<template>
    <div>
        <row ref="row" :base="$options.name"></row>
    </div>
</template>

<script>
    import row from '@/components/row.vue'

    export default {
        name: "laBiao",
        components:{
            row
        },
        data() {
            return {
                list:[
                    {
                        title: "使用前检查",
                        text:[
                            '#初始环境必须成功(图1)',
                            '#"江湖助力"的快捷键是默认N',
                        ],
                        img:[
                            "com/init.png",
                        ]
                    },
                    {
                        title: "功能说明",
                        text:[
                            "#切换到拉镖页",
                            "#按图1说明参数:",
                            "1.必填项-技能:打拉镖BOSS的输出技能,建议用最高输出套路并且设置冲锋技能",
                            "2.选填项-邀请玩家:可以在打完BOSS后,邀请帮会其他指定玩家,格式是:角色A+角色B+角色C",
                            "3.选填项-队伍转团队:默认创建6人队伍,如果蹭镖人多,可以勾选转化团队",
                            '4.选填项-自动喊话:勾选并且在"挂机-自动喊话"里设置好参数,在打完BOSS后自动在当前频道喊话',
                            '5.选填项-队伍不解散:默认每次开始拉镖时自动解散重新创建队伍,如果有不解散队伍的需求,' +
                            '可以勾选(注意:勾选后需要手动创建队伍再开始任务)',
                            '6.选填项-拉镖X次:默认0是一直拉镖,可以设置拉镖多少次后停止,图中是2次后停止',
                            '7.选填项-死亡X次:默认0是死亡后不停止拉镖,如果被人劫镖,可以设置死亡多少次后停止任务',
                            '8.选填项-死亡等待X分钟:如果被人劫镖,可以设置死亡后在复活点等待x分钟后再次继续拉镖',
                        ],
                        img:[
                            "1.png",
                        ]
                    },
                    {
                        title: "游戏说明",
                        text:[
                            "#注意识别区域,防止误使用药品食物(图1)",
                            '#右上角的"旗子"必须显示不被遮挡,否则会出现打完BOSS后解散队伍重新拉镖的现象(图2)',
                            "#把马哨/御风放到快捷栏上,移动时候自动使用",
                            "#把打坐技能放到快捷栏上,每次拉镖完半血自动打坐",
                            "#以上设置好,角色移动到五大主城场景,支持苏州,成都,燕京,洛阳,金陵,开始任务,自动识别场景",
                        ],
                        img:[
                            "2.png",
                            "3.png",
                        ]
                    },
                ],
            }
        },
        methods:{

        },
        mounted() {
            this.$refs.row.initData(this.list);
        }
    }
</script>

<style scoped>

</style>
